<template>
  <div class="container">
    <div class="box">
      <h4 class="title">Hulum Ticket</h4>
    </div>
    <div class="barcode-container">
      <svg ref="barcode"></svg>
    </div>

    <div v-if="isLoading" class="loading-container">
      <img
        src="https://cdn.dribbble.com/users/194846/screenshots/1452453/loadingspinner.gif"
        alt="Loading..."
        class="loading-gif"
      />
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isLoading: true,
    };
  }
};
</script>

<style>

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.loading-gif {
  max-width: 100px;
  max-height: 100px;
}


.box {
  text-align: center;
  margin-top: 70px;
  border-radius: 7pt;
}
.title {
  animation: rainbow 5s linear infinite;
}


@keyframes rainbow {
  0% {
    color: rgba(207, 8, 8, 0.788);
  }
  14% {
    color: #ff7f00;
  }
  28% {
    color: #021307a4;
  }
  42% {
    color: #5dbe1c;
  }
  57% {
    color: #04048ad3;
  }
  71% {
    color: #4b0082;
  }
  85% {
    color: #8f00ff;
  }
  100% {
    color: #09c9eb;
  }
}

@media (max-width: 200px) {
  .barcode-container {
    font-size: 16px;
  }
}

@media (max-width: 100px) {
  .barcode-container {
    font-size: 14px;
  }
}
</style>
