import { createRouter,createWebHistory } from "vue-router";

import BarCode from  './pages/BarCode.vue'
import DefaultPage from  './pages/DefaultPage.vue'



const routes = [

  { path: '/',name:'DefaultPage',component:DefaultPage },
  { path: '/barcode/:barcode',name:'BarCode',component:BarCode }
  ,
  
  ]
  
  const router = createRouter({
  history:createWebHistory(),
  routes,
  });
  
  export default router;