<template>
  <NavigationMenu title="BarCode" />
 <div class="container">
  <div class="row">
    <div class="col-md-12">
      <div>
        <router-view>
   </router-view>
 </div>
    </div>
  </div>
 </div>

</template>

<script>
import BarCode from './pages/BarCode.vue'
export default {
  name: 'App',
  components: {
    BarCode, 
  }
}
</script>

