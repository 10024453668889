<template>
  <div class="container">
    <div class="box">
      <h4 class="title">Hulum Ticket</h4>
    </div>
    <div class="barcode-container">
      <svg ref="barcode"></svg>
    </div>

    <div v-if="isLoading" class="loading-container">
      <img
        src="https://cdn.dribbble.com/users/194846/screenshots/1452453/loadingspinner.gif"
        alt="Loading..."
        class="loading-gif"
      />
    </div>
    <div v-if="noValueDetected" class="message">Ticket Number Not Found.</div>

    <div class="container">
      <div class="pass_info" v-if="passenger">
        <h6 class="title">Passenger Information</h6>
        
        <small>Full Name: {{ passenger.full_name }}</small>
        <small>Phone Number: {{ passenger.phone_number }}</small>
        <small>Seat Number: {{ passenger.seat_number }}</small>
        <small>Price: {{ passenger.price }}</small>
        <small>Date: {{ passenger.date.replace(/-/g, "/")    }}</small>
        <small>Payment Status: {{ passenger.payment_status }}</small>
        <small>Ticket Number: {{ passenger.ticket_number }}</small>
        <small>Rank: {{ passenger.rank }} </small>
        <small>Bus info : {{ passenger.bus_category == "Luxury"?passenger.business_type_name:null }}   
          {{ passenger.side_number }} /{{ passenger.plate_number }} ({{ passenger.bus_category }})</small>
        
      </div>
    </div>
  </div>
</template>

<script>
import JsBarcode from 'jsbarcode';
import axios from 'axios';

export default {
  data() {
    return {
      isLoading: true,
      noValueDetected: false,
      passenger: null,
      formData: {
        ticket_number: null
      }
    };
  },
  mounted() {
    const barcodeValue = this.$route.params.barcode || this.$route.query.barcode;
    if (barcodeValue !== null && barcodeValue !== '') {
      try {
        this.formData.ticket_number = barcodeValue;

        this.generateBarcode(barcodeValue);
        this.fetchPassengerData();
      } catch (error) {
        //console.error('Barcode generation error:', error);
        this.noValueDetected = true;
      }
    } else {
      this.noValueDetected = true;
    }
  },
  methods: {
    generateBarcode(value) {
      const barcodeElement = this.$refs.barcode;
      if (!barcodeElement || !barcodeElement.parentNode) return;

      barcodeElement.innerHTML = '';

      try {
        JsBarcode(barcodeElement, value);
      } catch (error) {
        //console.error('JsBarcode error:', error);
        this.noValueDetected = true;
      }
    },
    
  getLevel (number){

  const lastDigit = number % 10;
  const lastTwoDigits = number % 100;

  if (lastDigit === 1 && lastTwoDigits !== 11) {
    return `${number}st`;
  } else if (lastDigit === 2 && lastTwoDigits !== 12) {
    return `${number}nd`;
  } else if (lastDigit === 3 && lastTwoDigits !== 13) {
    return `${number}rd`;
  } else {
    return `${number}th`;
  }
},
    fetchPassengerData() {
           axios.post('https://bacckk.hulumticket.com.et/api/getSinglePassenger',  this.formData)
        .then((response) => {
          const passenger = response.data.data;

          if (passenger) {
            passenger.rank = this.getLevel(passenger.rank); 
            this.passenger = passenger;
          } else {
            this.noValueDetected = true;
          }
        })
        .catch(() => {
          // console.error('API error:', error);
          this.noValueDetected = true;
        })
        .finally(() => {
          this.isLoading = false; // Set loading state to false after fetching
        });
    },
  },
};
</script>

<style>
.pass_info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

}

.pass_info h6.title {
  grid-column: 1 / -1;
}

.pass_info .column {
  padding: 5px;
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.loading-gif {
  max-width: 100px;
  max-height: 100px;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box {
  text-align: center;
  margin-bottom: 1px;
}
.info_box {
  text-align: center;
  margin-bottom: 1px;
  border: 2px solid rgb(6, 75, 69), 236, 10;
}

.title {
  animation: rainbow 5s linear infinite;
}

.barcode-container {
  text-align: center;
}

.message {
  margin-top: 10px;
}

@keyframes rainbow {
  0% {
    color: #d12a2ac9;
  }
  14% {
    color: #ff7f00;
  }
  28% {
    color: #021307a4;
  }
  42% {
    color: #5dbe1c;
  }
  57% {
    color: #04048ad3;
  }
  71% {
    color: #4b0082;
  }
  85% {
    color: #8f00ff;
  }
  100% {
    color: #09c9eb;
  }
}

@media (max-width: 200px) {
  .barcode-container {
    font-size: 16px;
  }
}

@media (max-width: 100px) {
  .barcode-container {
    font-size: 14px;
  }
}
</style>
